@import "https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap";
* {
  font-family: Teachers;
}

body {
  background: radial-gradient(circle at 7.5% 24%, #eda1c1 0%, #fab2ac 25.5%, #bee4d2 62.3%, #d7f8f7 93.8%);
  min-height: 100vh;
}

.card {
  height: 100%;
  transition: all 1s;
  box-shadow: 5px 5px 5px #000;
}

.card:hover {
  background: linear-gradient(68.6deg, #fca5f1 1.8%, #b5ffff 100.5%);
  transform: scale(1.1);
}
/*# sourceMappingURL=index.1cc67fc1.css.map */
