@import url("https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap");

* {
  font-family: "Teachers";
}

body {
  background: radial-gradient(
    circle at 7.5% 24%,
    rgb(237, 161, 193) 0%,
    rgb(250, 178, 172) 25.5%,
    rgb(190, 228, 210) 62.3%,
    rgb(215, 248, 247) 93.8%
  );
  min-height: 100vh;
}

.card {
  height: 100%;
  transition: all ease 1s;
  box-shadow: 5px 5px 5px black;
  &:hover {
    transform: scale(1.1);
    background: linear-gradient(
      68.6deg,
      rgb(252, 165, 241) 1.8%,
      rgb(181, 255, 255) 100.5%
    );
  }
}
